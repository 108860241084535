
import TicketListItem from '~/components/TicketListItem.vue';
export default {
  name: 'Default',
  components: { TicketListItem },
  data() {
    return {
      drawer: null,
      fetchUserInterval: null,
      checkReleaseInterval: null,
      showReleaseWarning: false,
      isLoadingTickets: false,
      ticketCancelSource: null,
      relatedCancelSource: null,
      loadedTickets: null,
      observer: null,
      reloadInterval: null,
      relatedTickets: null,
      selectionTicketCount: null,
    };
  },
  async fetch() {
    await Promise.all([
      this.$store.dispatch('settings/load_sites'),
      this.$store.dispatch('settings/load_tradenames'),
      this.$store.dispatch('settings/load_maildomains'),
    ]);
  },
  head() {
    return {
      title: this.title,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
  computed: {
    title() {
      if (
        this.loadedTickets?.length &&
        this.ticketFilterStatus === 'needs-action'
      ) {
        return '(' + this.selectionTicketCount + ') Tickets';
      }
      return 'Tickets';
    },
    openTicketId() {
      if (this.$route.params.id) {
        return parseInt(this.$route.params.id) || null;
      }
      return null;
    },
    ticketFilterUser: {
      get() {
        return this.$store.state.ticket_search_user_id;
      },
      set(value) {
        this.$store.commit('setTicketSearchUserId', value || null);
      },
    },
    ticketFilterStatus: {
      get() {
        return this.$store.state.ticket_search_status;
      },
      set(value) {
        this.$store.commit('setTicketSearchStatus', value || '');
      },
    },
  },
  watch: {
    openTicketId(newId) {
      const index = this.loadedTickets?.findIndex(
        (x) => x.id === this.openTicketId
      );
      if (index !== -1) {
        this.loadedTickets[index].last_admin_open = new Date().toISOString();
      }
      // filter other tickets
      if (this.ticketFilterStatus) {
        this.loadedTickets = this.loadedTickets.filter(
          (x) =>
            x.status === this.ticketFilterStatus || x.id === this.openTicketId
        );
      }
      this.loadRelatedTickets();
    },
    ticketFilterUser() {
      this.loadTickets();
    },
    ticketFilterStatus() {
      if (this.loadedTickets !== null) {
        this.loadTickets(
          false,
          this.loadedTickets.findIndex((x) => x.id === this.openTicketId) !==
            -1 || this.openTicketId === null
        );
      }
    },
  },
  mounted() {
    this.checkRelease();
    if (!this.fetchUserInterval) {
      this.fetchUserInterval = setInterval(() => {
        if (this.$auth.loggedIn) {
          this.$auth.fetchUser();
        }
      }, 60000);
    }
    if (!this.checkReleaseInterval) {
      this.checkReleaseInterval = setInterval(() => {
        this.checkRelease();
      }, 30000);
    }

    if (this.$route.params.id === 'recent') {
      this.ticketFilterStatus = 'needs-action';
      this.ticketFilterUser = null;
    }

    this.loadTickets(false, this.openTicketId === null);
    window.addEventListener('keydown', this.keyDown);

    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (!this.isLoadingTickets) {
          this.loadTickets(true);
        }
      }
    });

    const el = document.getElementById('load-more');
    this.observer.observe(el);

    this.$nuxt.$on('ticketAnswered', (e) => {
      const index = this.loadedTickets?.findIndex((x) => x.id === e);
      if (index !== -1) {
        this.loadedTickets[index].status = 'answered';
      }
    });

    this.reloadInterval = setInterval(() => {
      if (this.loadedTickets !== null && this.loadedTickets.length < 10) {
        if (!this.isLoadingTickets) {
          this.loadTickets(true);
        }
      }
    }, 60000);

    this.loadRelatedTickets();
  },
  beforeDestroy() {
    if (this.fetchUserInterval) {
      clearInterval(this.fetchUserInterval);
      clearInterval(this.checkReleaseInterval);
    }
    window.removeEventListener('keydown', this.keyDown);
    clearInterval(this.reloadInterval);
  },
  created() {
    if (this.$route.query.user) {
      this.ticketFilterUser = this.$route.query.user;
      this.ticketFilterStatus = null;
    }
  },
  methods: {
    stopUserFilter() {
      if (!this.ticketFilterStatus) {
        this.ticketFilterStatus = 'needs-action';
      }
      this.ticketFilterUser = null;
    },
    deleteOpenTicket(e) {
      if (this.openTicketId) {
        const ticketToDelete = this.openTicketId;
        this.$axios.put('/api/tickets.php?id=' + ticketToDelete, {
          status: 'ignored',
        });

        if (this.ticketFilterStatus && this.ticketFilterStatus !== 'ignored') {
          // remove entry from list
          if (!this.moveOpenTicket(1)) {
            this.moveOpenTicket(-1);
          }

          this.loadedTickets = this.loadedTickets.filter(
            (x) => x.id !== ticketToDelete
          );
        }
      }
    },
    moveOpenTicket(indexChange) {
      const index = this.loadedTickets?.findIndex(
        (x) => x.id === this.openTicketId
      );

      const newIndex = index + indexChange;
      if (index !== -1 && newIndex >= 0) {
        const newTicket = this.loadedTickets[newIndex];
        if (newTicket) {
          this.$router.push(
            this.localePath({
              name: 'tickets-id',
              params: {
                id: newTicket.id,
              },
            })
          );
          const id = 'ticket-link-' + newTicket.id;
          if (!document.getElementById(id)?.classList?.contains('visible')) {
            window.document.getElementById(id)?.scrollIntoView({
              block: 'center',
            });
          }
          return true;
        }
      }
      return false;
    },
    keyDown(e) {
      if ((e.target || e.scrElement).tagName.toLowerCase() === 'body') {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          this.deleteOpenTicket();
        } else if (e.key === 'ArrowDown') {
          this.moveOpenTicket(+1);
        } else if (e.key === 'ArrowUp') {
          this.moveOpenTicket(-1);
        }
      }
    },
    loadTickets(more, autoOpen) {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
      this.isLoadingTickets = true;
      this.cancelSource = this.$axios.CancelToken.source();
      const params = {
        status: [this.ticketFilterStatus],
        user: this.ticketFilterUser,
      };
      if (more) {
        params.not = this.loadedTickets.map((x) => x.id);
      }
      this.$axios
        .get('/api/tickets.php', {
          params,
          cancelToken: this.cancelSource && this.cancelSource.token,
        })
        .then((res) => {
          this.selectionTicketCount = res.data.num_tickets;
          if (more) {
            this.loadedTickets.push(...res.data.tickets);
          } else {
            this.loadedTickets = res.data.tickets;
            if (autoOpen) {
              if (this.loadedTickets.length > 0) {
                this.$router.push(
                  this.localePath({
                    name: 'tickets-id',
                    params: {
                      id: this.loadedTickets[0].id,
                    },
                  })
                );
              } else {
                // empty mailbox page
                this.$router.push(
                  this.localePath({
                    name: 'tickets-empty',
                  })
                );
              }
            }
          }
          this.isLoadingTickets = false;
        })
        .catch(() => {});
    },
    loadRelatedTickets(more, autoOpen) {
      if (this.relatedCancelSource) {
        this.relatedCancelSource.cancel();
      }
      this.relatedTickets = null;
      if (!this.openTicketId) {
        return null;
      }
      this.relatedCancelSource = this.$axios.CancelToken.source();
      const params = {
        related: this.openTicketId,
      };
      this.$axios
        .get('/api/tickets.php', {
          params,
          cancelToken:
            this.relatedCancelSource && this.relatedCancelSource.token,
        })
        .then((res) => {
          this.relatedTickets = res.data.tickets;
        })
        .catch(() => {});
    },

    checkRelease() {
      if (process.env.NODE_ENV === 'production') {
        this.$axios
          .get('/last-release', {
            params: { t: Date.now() },
          })
          .then((res) => {
            this.showReleaseWarning =
              res.data.toString().trim() !==
              process.env.NUXT_ENV_LAST_RELEASE.trim();
          })
          .catch(() => {});
      }
    },
    refreshPage() {
      window.location.reload();
    },
  },
};
