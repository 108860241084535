/* eslint-disable prettier/prettier */
export const state = () => ({
  app_bar_title: '',
  skipped_conversations: [],
  member_search_text: '',
  member_search_sites: [],
  member_search_status: [],
  member_search_countries: [],
  member_search_trafficsources: [],
  member_search_device_types: [], 
  member_search_sub1: '', 
  member_search_sub2: '', 
  member_search_sub3: '', 
  member_search_os_name: '',
  member_search_os_version: '',
  member_search_browser_name: '',
  member_search_ua_filters_enabled: false,

  profile_search_text: '',
  profile_search_sites: [],
  profile_search_excluded_sites: [],
  profile_age_filter: [18, 100],
  profile_gender_filter: null,
  profile_country_filter: null,
  profile_countryregion_filter: null,
  userpayment_search_text: '',
  userpayment_search_sites: [],
  userpayment_search_status: ['completed'],
  userpayment_search_countries: [],
  userpayment_search_discountcode: '', userpayment_search_trafficsources: [],
  userpayment_search_paymentaccounts: [],
  userpayment_search_paymentmethods: [],
  userpayment_search_paymentproviders: [],

  pickupline_search_text: '',
  pickupline_search_topics: [],
  pickupline_search_languages: [],
  pickupline_search_fetishes: [],

  autopoke_search_text: '',
  autopoke_search_moments: [],
  autopoke_search_languages: [],
  autopoke_search_fetishes: [],


  autoreply_search_text: '',
  autoreply_search_languages: [],
  autoreply_search_fetishes: [],
  
  oa_publishers: null,
  highroller_sites: [],
  highroller_countries: [],
  highroller_countryregions: [],
  highroller_range: [],
  highroller_status: [],
  discountcode_search_text: '',
  discountcode_search_sites: [],
  discountcode_search_countries: [],
  discountcode_search_types: ['admin'],

  ticket_search_status: 'needs-action', 
  ticket_search_user_id: null,
});

export const mutations = {
  CHANGE_TITLE(state, title) {
    state.app_bar_title = title;
  },
  SKIP_CONVERSATION(state, conversationId) {
    state.skipped_conversations.push(conversationId);
  },
  REMOVE_ONE_SKIP_CONVERSATION(state, conversationId) {
    state.skipped_conversations.shift();
  },
  setMemberSearchText(state, value) {
    state.member_search_text = value;
  },
  setMemberSearchSites(state, value) {
    state.member_search_sites = value;
  },
  setMemberSearchTrafficsources(state, value) {
    state.member_search_trafficsources = value;
  },
  setMemberSearchStatus(state, value) {
    state.member_search_status = value;
  },
  setMemberSearchCountries(state, value) {
    state.member_search_countries = value;
  },
  setMemberSearchDeviceTypes(state, value) {
    state.member_search_device_types = value;
  },
  setMemberSearchSub1(state, value) {
    state.member_search_sub1 = value;
  },
  setMemberSearchSub2(state, value) {
    state.member_search_sub2 = value;
  },
  setMemberSearchSub3(state, value) {
    state.member_search_sub3 = value;
  },
  setMemberSearchOsName(state, value) {
    state.member_search_os_name = value;
  },
  setMemberSearchOsVersion(state, value) {
    state.member_search_os_version = value;
  },
  setMemberSearchBrowserName(state, value) {
    state.member_search_browser_name = value;
  },
  setMemberSearchUAFiltersEnabled(state, value) {
    state.member_search_ua_filters_enabled = value;
  },

  setUserpaymentSearchText(state, value) {
    state.userpayment_search_text = value;
  },
  setUserpaymentSearchSites(state, value) {
    state.userpayment_search_sites = value;
  },
  setUserpaymentSearchStatus(state, value) {
    state.userpayment_search_status = value;
  },
  setUserpaymentSearchCountries(state, value) {
    state.userpayment_search_countries = value;
  },
  setUserpaymentSearchDiscountcode(state, value) {
    state.userpayment_search_discountcode = value;
  },
  setUserpaymentSearchTrafficsources(state, value) {
    state.userpayment_search_trafficsources = value;
  },
  setUserpaymentSearchPaymentaccounts(state, value) {
    state.userpayment_search_paymentaccounts = value;
  },
  setUserpaymentSearchPaymentmethods(state, value) {
    state.userpayment_search_paymentmethods = value;
  },
  setUserpaymentSearchPaymentproviders(state, value) {
    state.userpayment_search_paymentproviders = value;
  },

  setProfileSearchText(state, value) {
    state.profile_search_text = value;
  },
  setProfileSearchSites(state, value) {
    state.profile_search_sites = value;
  },
  setProfileSearchExcludedSites(state, value) {
    state.profile_search_excluded_sites = value;
  },
  setProfileSearchAgeFilter(state, value) {
    state.profile_age_filter = value;
  },
  setProfileGenderFilter(state, value) {
    state.profile_gender_filter = value;
  },
  setProfileCountryFilter(state, value) {
    state.profile_country_filter = value;
  },
  setProfileCountryRegionFilter(state, value) {
    state.profile_countryregion_filter = value;
  },
  setPickuplineSearchText(state, value) {
    state.pickupline_search_text = value;
  },
  setPickuplineSearchTopics(state, value) {
    state.pickupline_search_topics = value;
  },
  setPickuplineSearchLanguages(state, value) {
    state.pickupline_search_languages = value;
  }, setPickuplineSearchFetishes(state, value) {
    state.pickupline_search_fetishes = value;
  }, 

  setAutoReplySearchText(state, value) {
    state.autoreply_search_text = value;
  },
  setAutoReplySearchLanguages(state, value) {
    state.autoreply_search_languages = value;
  },
   setAutoReplySearchFetishes(state, value) {
    state.autoreply_search_fetishes = value;
  },

  setOAPublishers(state, value) {
    state.oa_publishers = value;
  },
  setHighrollerCountries(state, value) {
    state.highroller_countries = value;
  },
  setHighrollerCountryRegions(state, value) {
    state.highroller_countryregions = value;
  },

  setHighrollerSites(state, value) {
    state.highroller_sites = value;
  },
  setHighRollerRange(state, value) {
    state.highroller_range = value;
  },
  setHighRollerStatus(state, value) {
    state.highroller_status = value;
  },
  setDiscountcodeSearchText(state, value) {
    state.discountcode_search_text = value;
  },
  setDiscountcodeSearchSites(state, value) {
    state.discountcode_search_sites = value;
  },
  setDiscountcodeSearchCountries(state, value) {
    state.discountcode_search_countries = value;
  },
  setDiscountcodeSearchTypes(state, value) {
    state.discountcode_search_types = value;
  },
  setTicketSearchStatus(state, value) {
    state.ticket_search_status = value;
  },
  setTicketSearchUserId(state, value) {
    state.ticket_search_user_id = value;
  },
  setAutopokeSearchText(state, value) {
    state.autopoke_search_text = value;
  },
  setAutopokeSearchMoments(state, value) {
    state.autopoke_search_moments = value;
  },
  setAutopokeSearchLanguages(state, value) {
    state.autopoke_search_languages = value;
  }, setAutopokeSearchFetishes(state, value) {
    state.autopoke_search_fetishes = value;
  },
};