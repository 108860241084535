
export default {
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      observer: null,
      fullyVisible: false,
    };
  },
  computed: {
    id() {
      return 'ticket-link-' + this.ticket.id;
    },
    tradename() {
      return (
        this.$store.state.settings.tradenames?.find(
          (x) => x.id === this.ticket.tradename_id
        ) || null
      );
    },
    maildomain() {
      return (
        this.$store.state.settings.maildomains?.find(
          (x) => x.id === this.ticket.maildomain_id
        ) || null
      );
    },

    site() {
      return (
        this.$store.state.settings.sites?.find(
          (x) => x.id === this.ticket.site_id
        ) || null
      );
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        //   console.log(entries);
        this.fullyVisible = entries[0].isIntersecting;
      },
      {
        threshold: 1.0,
      }
    );

    const el = document.getElementById(this.id);
    this.observer.observe(el);
  },
  beforeDestroy() {
    this.observer?.disconnect();
  },

  methods: {
    subtitleForDate(dateString) {
      const dayjsdate = this.$dayjs(dateString);

      let normalDate = dayjsdate.locale(this.$i18n.locale).format('LLL');
      if (dayjsdate.isToday()) {
        normalDate =
          this.$t('today') +
          ' ' +
          dayjsdate.locale(this.$i18n.locale).format('HH:mm');
      } else if (dayjsdate.isYesterday()) {
        normalDate =
          this.$t('yesterday') +
          ' ' +
          dayjsdate.locale(this.$i18n.locale).format('HH:mm');
      }

      return normalDate;
    },
  },
};
