export default {
  mastercard: 'Mastercard',
  visa: 'VISA',
  amex: 'AMEX',
  site_search: {
    none: 'Disabled',
    nonnude: 'Non nude profiles only',
    full: 'Full search enabled',
  },
  site_signups: {
    all: 'Open',
    with_trafficsource: 'Only with trafficsource',
    none: 'Only API',
  },
  discountcode_type: {
    admin: 'Gemaakt door admin',
    'account-delete': 'Gemaakt in account delete flow',
  },

  export_options: {
    export_trafficsource_costs: 'Export trafficsource costs',
    export_only_paid_leads: 'Export only paid leads',
    export_deducted_leads: 'Export deducted leads',
  },

  discountcode_validity: {
    always: 'Elke bestelling',
    'once-per-user': 'éénmalig per user',
    once: 'éénmalig geldig',
  },
  related_tickets: '1 related ticket | {count} related tickets',
  html: 'HTML',
  text: 'text',
  html_translated: 'HTML (translated)',
  text_translated: 'text (translated)',
  creditcard: 'Creditcard',
  ideal: 'iDEAL',
  bancontact: 'Bancontact',
  applepay: 'Apple Pay',
  nexi: 'Nexi (Creditcard)',
  postepay: 'Postepay',
  paysafecard: 'Paysafecard',
  paypal: 'PayPal',
  sofort: 'SOFORT',
  maestro: 'Maestro',
  giropay: 'Giropay',
  sct: 'SEPA',
  eps: 'EPS',
  alternativepayments: 'Alt Payments',
  paynl: 'Pay.nl',
  mstream: 'Mstream',
  importflevohost: 'Flevohost',
  dialxs: 'DialXS',
  targetpay: 'TargetPay',
  member_flags: {
    enable_full_history: 'Enable full message history',
    disable_recurring: 'Disable recurring payments',
    disable_creditcard: 'Disable creditcard payments',
    disable_paypal: 'Disable PayPal payments',
    privacy_mode: 'Privacy mode',
    disable_transaction_costs: 'Disable transaction costs',
  },
  without_region: 'Zonder provincie',
  DISCOUNTCODE_ADD: 'Kortingscode toevoegen',
  DISCOUNTCODE_ADD_USER: 'Kortingscode toevoegen voor {name}',
  additional_message: {
    none: 'None',
    optional_gift: 'Optional gift',
    optional_photo: 'Optional photo',
    forced_gift: 'Forced gift (only when gift mentioned in text)',
    forced_photo: 'Forced photo (only when photo mentioned in text)',
    after_text: 'After text message',
    before_text: 'Before text message',
    profilephoto: 'Profile photo',
    nudity: 'Photo with nudity visible',
    face: 'Photo with face visible',
  },

  pickupline_types: {
    'post-like': 'Reactie nadat gebruiker profiel geliket heeft',
    'post-match': 'Reactie na like match',
    normal: 'Pickup queue',
  },
  topic_reason: {
    model: 'ML Model',
    model_exact: 'ML Model - exact match',
    site: 'Sex-focussed website',
    photos: 'Nudity in photos',
    manual: 'Manually changed',
  },
  AGE_DISPLAY: '{age} jaar',

  breast_size: 'Borstformaat',
  not_visible: 'Niet zichtbaar',
  small: 'Klein',
  large: 'Groot',
  body_type: 'Lichaamstype',
  slim: 'Dun',
  normal: 'Normaal',
  fat: 'Overgewicht',
  attractiveness: 'Aantrekkelijkheid',
  ugly: 'Lelijk',
  average: 'Gemiddeld',
  beautifyl: 'Knap',
  race: 'Ras',
  white: 'Wit',
  asian: 'Aziatish',
  latino: 'Latino',
  arab: 'Arabish',
  appearance: 'Uitstraling',
  slut: 'Sletterig',
  naked: 'Naakt / lingerie',
  language: 'Taal',
  cnt_pokes: 'Aantal pokes',
  cnt_messages: 'Aantal berichten',
  cnt_operators: 'Aantal operators',
  Languages: 'Talen',
  oldest_message: 'Oudste bericht',
  newest_message: 'Nieuwste bericht',
  Permissions: 'Type',
  operator_name: 'Naam',
  open_conversations: 'Open gesprekken',
  no_open_conversations: 'Geen open gesprekken / in wachtrij',
  email_phone_first_message: 'Email / phone number filtered in first message',
  fictional_profileimage: 'Fictional profile image used',
  active: 'Active',
  disabled: 'Disabled',
  deleted: 'Deleted',
  quarantaine: 'Quarantaine',
  no_quarantaine: 'Niet in Quarantaine',
  finalized_signup: 'Aanmelding afgerond',
  banned: 'Banned',
  anonymized: 'Geanonimiseerd',
  changed_flags: 'Updated account settings',
  paid_user: 'Betaald lid',
  non_paid_user: 'Gratis lid',
  notifications_reactivated: 'Notifications heractiveerd',
  notifications_disabled: 'Notifications uitgeschakeld',
  not_confirmed: 'E-mail niet bevestigd',
  page_hidden: 'Page hidden',
  api_pending_click: 'API lead - Pending redirect click',
  api_pending_verify: 'API lead - Pending redirect verify',
  api_expired: 'API Lead - Redirect URL expired',
  duplicate_profileimage: 'Duplicate photo',
  duplicate_ip: 'Duplicate IP address',
  duplicate_email: 'Duplicate e-mail address',
  duplicate_email_banned: 'Banned account with same e-mail address',
  old_email: 'E-mail of deleted account',
  duplicate_profile: 'Duplicate member profile?',
  duplicate_push: 'Duplicate push subscription',
  missing_useragent: 'Missende user agent header, bot?',
  duplicate_payer_identifier: 'Duplicate payer identifier (IBAN,CC)',
  wrong_useragent_hash: 'Verkeerde user agent hash, bot?',
  multiple_forwarded_for: 'Onverwachte X-Forwarded-For Header (Proxy?)',
  timezone_mismatch: 'Timezone does not match country',
  notes_text: 'Over mij tekst',
  asn: 'Untrusted ASN (VPN?, BOT?)',
  tor: 'TOR exitpoint',
  credits_text: 'Credits mentioned in first message',
  akismet: 'Possible SPAM message',
  geoip: 'GEOIP does not match signup country',
  'No minimum': 'Geen minimum',
  'No maximum': 'Geen maximum',
  choose_country: 'Kies land',
  'Fictional profiles': 'Fictieve profielen',
  'User profile review': 'User profile review',
  CHAT_ADMIN_TITLE: 'Chat Admin',
  Members: 'Leden',
  Logout: 'Uitloggen',
  Search: 'Zoek',
  'Search now': 'Nu zoeken',
  VIEW_MEMBER: 'Bekijk {id}',
  'Photo approval': "Foto's keuren",
  group_by: {
    paymentmethod: 'Betaaldmethode',
    paymentprovider: 'Paymentprovider',
    paymentaccount: 'Paymentaccount',
    agegroup: 'Leeftijdsgroep',
    countryregion: 'Provincie',
    sexuality: 'Sekualiteit',
    trafficsource: 'Trafficsource',
    status: 'Account status',
    sub1: 'Subid 1',
    sub2: 'Subid 2',
    sub3: 'Subid 3',
    signup_path: 'Signup URI',
    team: 'Team',
    language: 'Taal',
    signup_referrer: 'HTTP Referrer',
    type: 'Type bericht',
    site: 'Site',
    country: 'Land',
    'payment-method': 'Betaalmethode',
    browser_name: 'Browser',
    browser_version: 'Browser version',
    os_name: 'OS',
    os_version: 'OS Version',
    device_type: 'Device type',
    mailprovider: 'E-mailprovider',
    gender: 'Geslacht',
  },
  MEMBER_TITLE: 'Leden: {name}',
  Revenue: 'Omzet',
  'Revenue report': 'Omzet overzicht',
  Summary: 'Samenvatting',
  'Group by': 'Groepeer op',
  PROFILE_TITLE: 'Profiel: {name}',
  choose_region: 'Kies regio',
  eye_color: 'Kleur ogen',
  hair_color: 'Kleur haar',
  relationship_status: 'Relatiestatus',
  personal: 'Over mij',
  bi: 'Bi-seksueel',
  lesbian: 'Lesbisch',
  hetero: 'Hetero',
  gender: ' Geslacht',
  sexuality: 'Seksualiteit',
  male: 'Man',
  female: 'Vrouw',
  'Profile info': 'Profiel info',
  Photos: "Foto's",
  gay: 'Gay',
  Profiles: 'Profielen',
  no: 'Nee',
  yes: 'Ja',
  reason: 'Reden van aanmelding',
  job: 'Werk',
  smokes: 'Rookt',
  City: 'Woonplaats',
  sometimes: 'Soms',
  blue: 'Blauw',
  green: 'Groen',
  hazel: 'Hazel',
  brown: 'Bruin',
  other: 'Anders',
  stopping: 'Probeer te stoppen',
  pets: 'Huisdieren',
  black: 'Zwart',
  gray: 'Grijs',
  bald: 'Kaal',
  married: 'Getrouwd',
  'living together': 'Samenwonend',
  divorced: 'Gescheiden',
  unmarried: 'Ongehuwd',
  widowed: 'Weduwe',
  hobbies: "Hobby's",
  children: 'Kinderen',
  blond: 'Blond',
  single: 'Vrijgezel',
  piercings: 'Piercings',
  tattoos: 'Tatoeages',
  likes: 'Houdt van',
  sports: 'Sport',
  red: 'Rood',
  important: 'Belangrijk',
  'Linked sites': 'Linked sites',
  'dark blond': 'Donker blond',
  'dark brown': 'Donker bruin',
  Year: 'Jaar',
  Month: 'Maand',
  Day: 'Dag',
};
