import Vue from 'vue';
const mixins = {
  methods: {
    countryEmoji(countryCode) {
      return this.$store.state.settings.countries.find(
        (x) => x.code === countryCode
      )?.emoji;
    },
    countryName(countryCode) {
      if (Intl && Intl?.DisplayNames && Intl?.DisplayNames !== null) {
        const displayNames = new Intl.DisplayNames(this.$i18n.locale, {
          type: 'region',
        });
        try {
          return displayNames.of(countryCode);
        } catch (e) {}
      }
      return countryCode;
    },
    languageName(locale) {
      if (Intl && Intl?.DisplayNames && Intl?.DisplayNames !== null) {
        const displayNames = new Intl.DisplayNames(this.$i18n.locale, {
          type: 'language',
        });
        try {
          return displayNames.of(locale.replace('_', '-'));
        } catch (e) {}
      }
      return locale;
    },
  },
  computed: {
    trafficsourceItemsWithNoTrafficsource() {
      const x = this.trafficsourceItems;

      x.push({
        text: this.$t('No trafficsource'),
        value: null,
      });

      return x;
    },
    trafficsourceItems() {
      if (this.$store.state.settings.trafficsources) {
        return this.$store.state.settings.trafficsources
          .filter((x) => x.status === 'active' && !x.hidden)
          .map((x) => {
            return {
              text:
                x.id +
                ' - ' +
                x.name +
                ' (' +
                this.siteOwners[x.owner]?.name +
                ')',
              value: x.id,
            };
          });
      }
      return [];
    },
    siteOwners() {
      const x = {};
      if (this.$store.state.settings.sites) {
        for (const key in this.$store.state.settings.sites) {
          const site = this.$store.state.settings.sites[key];
          if (site.hasPermission) {
            if (!Object.prototype.hasOwnProperty.call(x, site.owner_id)) {
              x[site.owner_id] = {
                name: site.owner,
                sites: [site.id],
              };
            } else {
              x[site.owner_id].sites.push(site.id);
            }
          }
        }
      }
      return x;
    },
    siteItems() {
      if (this.$store.state.settings.sites) {
        return this.$store.state.settings.sites
          .filter((x) => x.hasPermission)
          .filter((x) => x.countries.length > 0)
          .map((x) => {
            return {
              text: (x.admin_name ?? x.name) + ' (' + x.owner + ')',
              value: x.id,
            };
          });
      }
      return [];
    },
    siteGroupItems() {
      if (this.$store.state.settings.sitegroups) {
        return this.$store.state.settings.sitegroups.filter(
          (x) => x.hasPermissionToAllSites
        );
      }

      return [];
    },
  },
};

if (!Vue.__twizie_mixin__) {
  Vue.__twizie_mixin__ = true;
  Vue.mixin(mixins);
}
