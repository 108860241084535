import dayjs from 'dayjs'

import 'dayjs/locale/en'
import 'dayjs/locale/nl'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(duration)

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
